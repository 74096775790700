import { signIn } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import Image from 'next/image'

import ScoirLoginImage from './buttons/LogInWithScoir.png'

interface ScoirLoginButtonProps {
  callbackUrl: string
}
const ScoirLoginButton = (props: ScoirLoginButtonProps) => {
  const { callbackUrl } = props
  const t = useTranslations('Login')
  return (
    <div
      className="relative flex h-[2.6rem] w-full items-center justify-center p-0"
      onClick={() => signIn('scoir', { callbackUrl: callbackUrl })}
    >
      <Image
        src={ScoirLoginImage}
        fill={true}
        style={{ objectFit: 'scale-down' }}
        alt={t('loginWith', { provider: 'Scoir' })}
        className="cursor-pointer"
        sizes="100vw"
      />
    </div>
  )
}

export default ScoirLoginButton
